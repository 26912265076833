import { render, staticRenderFns } from "./ArticleLink.vue?vue&type=template&id=65722e88&scoped=true"
import script from "./ArticleLink.vue?vue&type=script&lang=ts"
export * from "./ArticleLink.vue?vue&type=script&lang=ts"
import style0 from "./ArticleLink.vue?vue&type=style&index=0&id=65722e88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65722e88",
  null
  
)

export default component.exports