
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { ArticleLinkBlokInterface } from '@energysage/storyblok-shared';

export default defineComponent({
    name: 'ArticleLink',
    props: {
        blok: {
            type: Object as PropType<ArticleLinkBlokInterface>,
            required: true,
        },
    },
});
